import Dropdown from "./Dropdown";
import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import "./Navbar.css";
import { Icon } from "@iconify/react";
import menuAltLeft from "@iconify/icons-bx/menu-alt-left";

export default function NavbarComponent() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        style={{
          boxShadow: "0px 2px 8px 0px #000000",
          zIndex: "3",
        }}
      >
        <Container>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            // className="order-lg-1 order-0"
            style={{
              border: "none",

              margin: 0,
              padding: 0,
            }}
          >
            <Icon icon={menuAltLeft} width="40" height="40" />
          </Navbar.Toggle>
          <Navbar.Brand
            href="#"
            className="mx-auto"
            style={{ paddingRight: "40px" }}
          >
            <img src="./Logo_v3.png" alt="" className="logoImg" />
          </Navbar.Brand>
          <Navbar.Collapse id="navbarScroll">
            <Nav className="mx-auto" navbarScroll>
              <Nav.Link href="#action2" style={{ fontWeight: "500" }}>
                CORPORATE ENQUIRY
              </Nav.Link>
              <Nav.Link href="#action2" style={{ fontWeight: "500" }}>
                CONTACT US
              </Nav.Link>
              <Nav.Link href="#action2" style={{ fontWeight: "500" }}>
                ABOUT US
              </Nav.Link>
            </Nav>
            {/* <Form className="d-flex">
              <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                style={{ borderRadius: "0px" }}
              />
            </Form>
            <Nav
              style={{ maxHeight: "100px" }}
              className="me-auto my-2 my-lg-0"
            >
              <Nav.Link href="#action2">Account</Nav.Link>
              <Nav.Link href="#action2">Wishlist</Nav.Link>
              <Nav.Link href="#action2">Cart</Nav.Link>
            </Nav> */}
            <div
              // className="mx-auto"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // width: "100%",
              }}
            >
              <img
                className="banner__item2VegIcon"
                src="./veg-icon.png"
                alt="veg-icon"
                style={{ paddingRight: "5px" }}
              />
              <div>Pure Vegetarian</div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
