import React from "react";
import { Container } from "react-bootstrap";
import "./KesarBanner.css";

const KesarBanner = () => {
  const text = `Hi I would like to know more about your Special Kesar Ghevar`;

  return (
    <Container>
      <div className="kesarBanner">
        <div className="kesarBanner__left"></div>
        <a
          href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_PHONE}&text=${text}&app_absent=0`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="./specialKesar.svg" alt="" />
        </a>
      </div>
    </Container>
  );
};

export default KesarBanner;
