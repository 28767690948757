import React, { useState } from "react";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import "./NewsletterForm.css";

const NewsletterForm = () => {
  const [formOpen, setFormOpen] = useState(true);
  return (
    formOpen && (
      <div className="formC">
        <Card
          style={{
            position: "absolute",
            width: "387px",
            height: "255px",
            marginTop: "320px",
            marginLeft: "50px",
            backgroundColor: "rgba(225,225,225,0.9)",
          }}
        >
          <img
            src="./button.png"
            style={{
              position: "absolute",
              width: "25px",
              marginLeft: "367px",
              top: "-10px",
              paddingTop: "0",
              cursor: "pointer",
            }}
            onClick={() => {
              setFormOpen(!formOpen);
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "255px",
              width: "320px",
              padding: "5px 15px 1px 20px",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: "18px",
                // marginTop: "10px",
              }}
            >
              <span>FRESH SEASONAL COLLECTION</span>
            </div>
            <div
              style={{
                fontWeight: "300",
                fontSize: "13px",
                width: "260px",
                // letterSpacing: "0.5px",
                lineHeight: "14.22px",
              }}
            >
              <span>
                SIGN UP FOR OUR NEWSLETTER AND GET A PERSONALIZED DISCOUNT CODE
                FOR YOUR SWEET CRAWINGS NOW!
              </span>
            </div>
            <div>
              <Form.Control
                type="password"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Name"
                size="sm"
                style={{
                  marginTop: "5px",
                  borderRadius: "0",
                  color: "#833440",
                }}
              />
            </div>
            <div>
              <Form.Control
                type="password"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                placeholder="Email"
                size="sm"
                style={{
                  marginTop: "5px",
                  borderRadius: "0",
                  color: "#833440",
                }}
              />
            </div>
            <div>
              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  size="sm"
                  style={{
                    backgroundColor: "#8D3C4C",
                    border: "none",
                    marginTop: "10px",
                    height: "40px",
                  }}
                >
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    )
  );
};

export default NewsletterForm;
