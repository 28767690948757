import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Banner.css";
const Banner = () => {
  return (
    <div className="banner__wrapper">
      <Container>
        <Row className="justify-content-md-center">
          {/* <Col sm={12} md={6}>
            <div className="banner__item1">
              <span>Shipping all over Mumbai</span>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="banner__item2">
              <img
                className="banner__item2VegIcon"
                src="./veg-icon.png"
                alt="veg-icon"
              />
              <span>Pure Vegitarian</span>
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
