import Homepage from "./layouts/Homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import WhatsappChat from "./components/WhatsappChat";
function App() {
  return (
    <div className="App">
      <Homepage />
      <WhatsappChat />
    </div>
  );
}

export default App;
