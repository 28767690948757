import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Dropdown from "../Navbar/Dropdown";
const Menu = () => {
  const items = [
    {
      url: "./Dryfruits.png",
      name: "Dryfruits",
    },
    {
      url: "./Sweets.png",
      name: "Sweets",
    },
    {
      url: "./Namkeen.png",
      name: "Namkeen",
    },
    {
      url: "./image 26.png",
      name: "Gift Hamper",
    },
  ];
  return (
    <Container>
      <Row className="mt-5">
        {items.map((item) => {
          return (
            <Col className="item m-0" xs={6} sm={6} md={3}>
              <img src={item.url} alt="" className="item__image" />
              <span className="item__name">{item.name}</span>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Menu;
