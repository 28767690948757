import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import imgg from "./chocolate-banner.png";
import "./ChocolateBanner.css";

const ChocolateBanner = () => {
  return (
    <div
      className="cont"
      style={{
        backgroundImage: `url(${imgg})`,
        height: "386px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Col
        sm={12}
        style={{
          color: "white",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingBottom: "40px",
          height: "100%",
        }}
      >
        <p
          style={{
            fontSize: "30px",
            padding: "0",
            margin: "0",
          }}
        >
          SPECIAL HOME MADE
        </p>
        <p
          className="choc"
          //   style={{
          //     fontSize: "92px",
          //     padding: "0",
          //     margin: "0",
          //     lineHeight: "1",
          //   }}
        >
          CHOCOLATES
        </p>
        <p className="hashtag">#melt_in_mouth</p>

        <Row
        //   style={{ textAlign: "center", display: "flex", alignItems: "center" }}
        >
          <Button
            className="shopNow"
            onClick={() => {
              const text = `Hi I would like to know more about your CHOCOLATES`;
              // window.location.href = `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_PHONE}&text=${text}&app_absent=0`;
              window.open(
                `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_PHONE}&text=${text}&app_absent=0`,
                "_blank"
              );
            }}
            style={{
              width: "106px",
              //   height: "49px",
              borderRadius: "4px",
              background: "#ffffff",
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "500",
              border: "none",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
              color: "black",
              marginTop: "24px",
            }}
          >
            SHOP NOW
          </Button>
        </Row>
      </Col>
    </div>
  );
};

export default ChocolateBanner;
