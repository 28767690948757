import React from "react";
import "./Product.css";
export const Product = ({ path, name }) => {
  return (
    <div className="product">
      <img className="product__img" src={path} alt="xvbc" />
      <span className="product__name">{name}</span>
    </div>
  );
};
