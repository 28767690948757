import React from "react";
import { Card, Row, Button, Col, Link } from "react-bootstrap";
import "./Newsletter.css";
const Newsletter = ({ productName }) => {
  return (
    <Row
      sm={12}
      style={{
        // position: "absolute",
        width: "100%",
        marginTop: "300px",
        marginLeft: "0px",
        // marginLeft: "50px",
      }}
    >
      <Col
        md={12}
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          //   marginLeft: "auto",
          color: "white",
        }}
      >
        <div className="cont">
          <p
            style={{
              fontSize: "30px",
              padding: "0",
              margin: "0",
            }}
          >
            NOW TASTE AUTHENTIC
          </p>
          <p className="choc">{productName}</p>

          <Button
            className="shopNow"
            onClick={() => {
              const text = `Hi I would like to know more about your ${productName}`;
              // window.location.href = `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_PHONE}&text=${text}&app_absent=0`;
              window.open(
                `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_PHONE}&text=${text}&app_absent=0`,
                "_blank"
              );
            }}
            style={{
              width: "106px",
              //   height: "49px",
              borderRadius: "4px",
              background: "#ffffff",
              fontFamily: "Rubik",
              fontSize: "14px",
              fontWeight: "500",
              border: "none",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
              color: "black",
              marginTop: "24px",
            }}
          >
            SHOP NOW
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Newsletter;
