import React from "react";
import "./Footer.css";
import { Form, Button, Container } from "react-bootstrap";
const Footer = () => {
  return (
    <section className="footerBg">
      <Container>
        <footer class="footer">
          <div className="footer__company">
            <h6 className="footer__heading">BHAVESH DRYFRUITS</h6>
            <p className="footer__content">
              Borivali’s favourite sweets, chocholatess, cookies, . Shipped
              right to your doorstep, anywhere in Mumbai Suburb!
            </p>
            <p className="footer__content">
              All Natural | Pure Vegetarian | Vacuum Packed | Free Shipping
            </p>
          </div>
          <div className="footer__policies">
            <h6 className="footer__heading">OUR POLICIES</h6>
            <a href="#">Search</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Return Policy</a>
            <a href="#">Terms of Services</a>
            <a href="#">Refund Policy</a>
          </div>
          <div className="footer__shop">
            <h6 className="footer__heading">SHOP</h6>
            <a href="#">SHOP</a>
            <a href="#">New</a>
            <a href="#">Arrivals</a>
            <a href="#">Croissants</a>
            <a href="#">Sweets</a>
            <a href="#">Breads</a>
            <a href="#">Gift Hampers</a>
          </div>
          <div className="footer__newsletter">
            <h6 className="footer__heading">NEWSLETTER</h6>
            <p href="#">
              Subscribe to receive updates access to exclusive deals. and more
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  className="footer__textField"
                  type="email"
                  placeholder="Enter your email address"
                  style={{
                    borderRadius: "0",
                    width: "254px",
                    height: "47px",
                    backgroundColor: "#E4E4E4",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "13px",
                    lineHeight: "15px",

                    color: "#8D8A8A",
                  }}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                style={{
                  backgroundColor: "black",
                  borderRadius: "0",
                  width: "147px",
                  height: "46px",
                  backgroundColor: "#1C1B1B",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  textAlign: "center",
                }}
              >
                SUBSCRIBE
              </Button>
            </Form>
          </div>
        </footer>
      </Container>
    </section>
  );
};

export default Footer;
