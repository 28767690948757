import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Product } from "../product";
import "./Products.css";

const Products = () => {
  const defaultTitle = "OUR CATEGORIES";
  const products = [
    {
      path: "./products/bakery.png",
      name: "BAKERY",
      innerProducts: [
        { path: "./products/bakery.png", name: "Cakes" },
        {
          path: "./products/bakery.png",
          name: "Pastries",
        },
        {
          path: "./products/bakery.png",
          name: "Bread",
        },
        {
          path: "./products/bakery.png",
          name: "Loaf",
        },
      ],
    },
    {
      path: "./products/dips.png",
      name: "DIPS, SAUSCES & DRESSING",
      innerProducts: [
        { path: "./products/dips.png", name: "Jalapeno Dip" },
        { path: "./products/dips.png", name: "Cheese Dip" },
        { path: "./products/dips.png", name: "Tangy Guacamole" },
        { path: "./products/dips.png", name: "Taco Dip" },
      ],
    },
    {
      path: "./products/chocolate.png",
      name: "CHOCOLATES",
      innerProducts: [
        { path: "./products/chocolate.png", name: "White Chocolate" },
        { path: "./products/chocolate.png", name: "Milk Chocolate" },
        { path: "./products/chocolate.png", name: "Dark Chocolate" },
        { path: "./products/chocolate.png", name: "Bittersweet Chocolate" },
        { path: "./products/chocolate.png", name: "Ruby Chocolate" },
        { path: "./products/chocolate.png", name: "Cocoa Powder" },
      ],
    },
    {
      path: "./products/readytoeat.png",
      name: "READY TO EAT",
      innerProducts: [
        { path: "./products/readytoeat.png", name: "Ready to eat 1" },
        { path: "./products/readytoeat.png", name: "Ready to eat 2" },
        { path: "./products/readytoeat.png", name: "Ready to eat 3" },
      ],
    },
    {
      path: "./products/drinks.png",
      name: "DRINKS & BEVERAGES SYRUP",
      innerProducts: [
        { path: "./products/drinks.png", name: "Butterscotch Syrups" },
        { path: "./products/drinks.png", name: "Toffee Syrups" },
        { path: "./products/drinks.png", name: "Mint Syrups" },
        { path: "./products/drinks.png", name: "Fruit Syrups" },
      ],
    },
    {
      path: "./products/healthy.png",
      name: "HEALTHY FOR FASTING SNACKS",
      innerProducts: [
        { path: "./products/healthy.png", name: "HealthyFood1" },
        { path: "./products/healthy.png", name: "HealthyFood2" },
        { path: "./products/healthy.png", name: "HealthyFood3" },
        { path: "./products/healthy.png", name: "HealthyFood4" },
      ],
    },
    {
      path: "./products/mukhwas.png",
      name: "MUKHWAS",
      innerProducts: [
        { path: "./products/mukhwas.png", name: "Dry Pan" },
        { path: "./products/mukhwas.png", name: "Dhana Dal" },
        { path: "./products/mukhwas.png", name: "Rajwadi" },
        { path: "./products/mukhwas.png", name: "Jaipuri" },
      ],
    },
    {
      path: "./products/juice.png",
      name: "JUICES",
      innerProducts: [
        { path: "./products/juice.png", name: "Mango" },
        { path: "./products/juice.png", name: "Strawberry" },
        { path: "./products/juice.png", name: "Watermelon" },
        { path: "./products/juice.png", name: "Pineapple" },
      ],
    },
  ];

  const [isProductClicked, setisProductClicked] = useState(false);
  const [productTitle, setProductTitle] = useState(defaultTitle);
  const [activeProducts, setActiveProducts] = useState(products);

  const onProductClick = (product) => {
    console.log(product);

    console.log(isProductClicked);

    setProductTitle(product.name);
    setActiveProducts(product.innerProducts);
    setisProductClicked(!isProductClicked);
  };

  const resetProducts = () => {
    setisProductClicked(false);
    setActiveProducts(products);
    setProductTitle(defaultTitle);
  };

  return (
    <div className="products__flex">
      <div className="products__heading">{productTitle}</div>

      <Container>
        <div
          className={`backButton ${
            isProductClicked ? "clicked" : "notClicked"
          }`}
          onClick={resetProducts}
        >{`< Back`}</div>
        <Row>
          {isProductClicked ? (
            <>
              {activeProducts.map((product) => {
                return (
                  <Col
                    className="scale-in-center2"
                    md={3}
                    sm={4}
                    xs={6}
                    onClick={() => {
                      if (!isProductClicked) {
                        onProductClick(product);
                      }
                    }}
                  >
                    <Product
                      path={product.path}
                      name={product.name}
                      className="product"
                    />
                  </Col>
                );
              })}
            </>
          ) : (
            <>
              {activeProducts.map((product) => {
                return (
                  <Col
                    className="scale-in-center"
                    md={3}
                    sm={4}
                    xs={6}
                    onClick={() => {
                      if (!isProductClicked) {
                        onProductClick(product);
                      }
                    }}
                  >
                    <Product
                      path={product.path}
                      name={product.name}
                      className="product"
                    />
                  </Col>
                );
              })}
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Products;
