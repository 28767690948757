import React from "react";
import "./WhatsappChat.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const WhatsappChat = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Chat with us
    </Tooltip>
  );

  return (
    <div className="whatsappChat">
      <a
        href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_PHONE}&text=Hi%20welcome%20to%20Bhavesh%20dryfruits&app_absent=0`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <img src="./whatsapp.png" alt="whatsapp" />
        </OverlayTrigger>
      </a>
    </div>
  );
};

export default WhatsappChat;
