import React from "react";
import { Carousel } from "react-bootstrap";
import "./Carousel.css";
import Newsletter from "./Newsletter";
import { Card, Row, Button, Col } from "react-bootstrap";

import NewsletterForm from "./NewsletterForm";

const CarouselHero = () => {
  return (
    <>
      {/* <Newsletter /> */}
      <NewsletterForm />

      <Carousel
        // controls={true}
        indicators={"round"}
        className="carouselStryles"
        variant="light"
      >
        <Carousel.Item>
          <img
            className="w-100 imgg"
            src="./c1.png"
            alt="First slide"
            style={{
              filter: "brightness(0.7)",
            }}
          />
          <Carousel.Caption>
            <Newsletter productName="DRY FRUITS" />
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 imgg"
            src="./c2.png"
            alt="Second slide"
            style={{
              filter: "brightness(0.7)",
            }}
          />
          <Carousel.Caption>
            <Newsletter productName="NAMKEEN" />
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 imgg"
            src="./c3.png"
            alt="Third slide"
            style={{
              filter: "brightness(0.7)",
            }}
          />
          <Carousel.Caption>
            <Newsletter productName="CHOCOLATES" />
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="w-100 imgg"
            src="./c4.png"
            alt="Third slide"
            style={{
              filter: "brightness(0.7)",
            }}
          />
          <Carousel.Caption>
            <Newsletter productName="SWEETS" />
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default CarouselHero;
